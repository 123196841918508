import { SPEAKER_ROLE_FOR_SESSION_FRAGMENT } from '@/graphql/_Fragments/SpeakerRole/ForSession';

export const SESSION_TIMELINE_FRAGMENT = `
  fragment sessionTimelineFragment on Session {
    uid
    id
    schemaCode
    name
    description
    startTime
    endTime
    geozone {
      uid
      name
    }
    speakerRoles {
      ...speakerRoleForSessionFragment
    }
  }
  ${SPEAKER_ROLE_FOR_SESSION_FRAGMENT}
`;
